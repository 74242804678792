import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import store from './store';
import Store from 'store';

import './scss/style.scss';
import apiClient from './_utils/axios';
import i18n from './lang/i18n';
import queryString from 'query-string';
import Analytics from './analytics';
import { loginAnonymous } from './_actions/userAction';
import { sendAnonymousCartsForUser } from './_utils/firebase-db';
import ChannelService from './components/channelTalk/ChannelService';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const MainLayout = React.lazy(() => import('./layout/MainLayout'));


class App extends Component {
  componentDidMount() {

    // const stageCheck = process.env.REACT_APP_API_URL !== "https://api.textiledesignbank.com/v1/"
    
    const now = new Date().getTime()
    let userData = Store.get('userData') ? Store.get('userData') : { name:`익명${now}`, id:`익명${now}`}

    // 채널톡 비활성화 - 주석제거하면 채널톡 기능 사용
    // ChannelService.boot({
    //   "pluginKey": "55bef7a7-7c2d-4d6c-b5a6-2cdc43770b09", //please fill with your plugin key
    //   "memberId": userData.id,
    //   "profile": {
    //     "name": userData.name,
    //     "email": userData.email, 
    //     "id": userData.id
    //   }
    // });

    if (!Store.get('lang')) {
      if (navigator.language === 'ko-KR' || navigator.language === 'ko') {
        Store.set('lang', 'kr');
      } else {
        Store.set('lang', 'en');
      }
    }

    const query = queryString.parse(window.location.search);
    if (query.hl) {
      if (query.hl === 'en') {
        Store.set('lang', 'en');
        i18n.changeLanguage('en');
      }

      if (query.hl === 'ko') {
        Store.set('lang', 'kr');
        i18n.changeLanguage('kr');
      }
    }

    /** 
     * 유입 링크의  Command 를 저장. 이를 통해 유입 및 속성 파악
     */
    if (query.command) {
      Store.set('command', query.command)
    }

    if (Store.get('lang') !== 'kr') {
      document.querySelector('meta[property="og:title"]').setAttribute('content', 'Textile Design Bank');
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', `Textile Design Bank is a design agent platform for global print pattern designs.`);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute('content', `Textile Design Bank is a design agent platform for global print pattern designs.`);
    }

    if (Store.get('accessToken') !== undefined) {
      sendAnonymousCartsForUser();
      this.loadMyInfo();
      this.onNoticeCount(); //장바구니 목록 개수 추가 
      this.onCartCount();
      this.getInterest();

      // setInterval(() => {
      //   this.onNoticeCount(); //장바구니 목록 개수 추가 
      //   this.onCartCount();
      // }, 3000);
    } else {
      loginAnonymous();
    }
    this.loadBankList();
    this.loadCategogy();
    this.loadStyle();
    this.loadLicensePrice();
    this.loadGeneratePrice();
    // this.getPopup()
  }

  loadMyInfo = async () => {
    const res = await apiClient.get('/web/my/info');
    const {
      user_id,
      user_uuid,
      username,
      email,
      phone,
      country_code,
      is_cert,
      is_email_verified,
      is_vip,
      name,
      nickname,
      profile_img,
      register_country,
      register_type,
      user_cash_krw,
      user_cash_usd,
      user_cash_eur,
      user_header_banner_img,
      vip_type,
      user_website,
      user_introduce,
      seller_account_status,
      seller_accounts,
      vip_end_datetime,
      currency,
    } = res.data;
    
    store.dispatch({
      type: 'set',
      payload: {
        user_id,
        is_login: true,
        user_uuid,
        username,
        email,
        phone,
        country_code,
        is_cert,
        is_email_verified,
        is_vip,
        name,
        nickname,
        profile_img,
        register_country,
        register_type,
        user_cash_krw,
        user_cash_usd,
        user_cash_eur,
        currency,
        user_header_banner_img,
        vip_type,
        user_website,
        user_introduce,
        seller_account_status,
        seller_accounts,
        vip_end_datetime,
      },
    });
  };

  loadBankList = async () => {
    const res = await apiClient.get('/web/bank');

    store.dispatch({
      type: 'set',
      payload: {
        bank_list: res.data,
      },
    });
  };

  loadCategogy = async () => {
    const res = await apiClient.get('/web/category');

    if (res) {
      store.dispatch({
        type: 'set',
        payload: {
          category: res.data,
        },
      });
    }
  };

  loadStyle = async () => {
    const res = await apiClient.get('/web/style');
    if (res) {
      store.dispatch({
        type: 'set',
        payload: {
          style: res.data,
        },
      });

      let mainViewStyle = [];
      res.data.forEach(style => {
        if (
          style.style_name_ko === "꽃" ||
          style.style_name_ko === "자연" ||
          style.style_name_ko === "동물무늬" ||
          style.style_name_ko === "추상" ||
          style.style_name_ko === "기하학" ||
          style.style_name_ko === "에스닉"
        ) {
          mainViewStyle.push(style)
        }

        store.dispatch({
          type: 'set',
          payload: {
            main_style_view: mainViewStyle,
          },
        });
      });
    }
  };

  loadLicensePrice = async () => {
    const res = await apiClient.get('/web/license/price');

    if (res) {
      store.dispatch({
        type: 'set',
        payload: {
          license_price: res.data,
        },
      });
    }
  };


  loadGeneratePrice = async () => {
    const res = await apiClient.get('/generate/price');

    if (res) {
      store.dispatch({
        type: 'set',
        payload: {
          generate_price: res.data,
        },
      });
    }
  };


  onNoticeCount = async () => {
    const res = await apiClient.get('/web/notice/count');

    if (res) {
      store.dispatch({
        type: 'set',
        payload: {
          notice_count: res.data.count,
        },
      });
      this.forceUpdate();
    }
  };



  //장바구니에 담긴 상품의 개수
  onCartCount = async () =>{

    const res = await apiClient.get('/web/cart/count');

    if(res){
      store.dispatch({
        type : 'set',
        payload : {
          totalCartCount: res.data.totalCount,
          cartCount: res.data.cartCount,
          aiCartCount : res.data.aiCartCount
        }
      })
    }

  }

  getInterest = async () => {
    const res = await apiClient.get('/web/interest');
    if (res) {
      if(res.data.length < 1){
        return
      }
      let interest_array = [];

      for (var i = 0; i < res.data.length; i++) {
        for (var k = 0; k < res.data[i].interest_product.length; k++) {
          if (!interest_array.includes(res.data[i].interest_product[k].product_id)) {
            interest_array.push(res.data[i].interest_product[k].product_id);
          }
        }
      }

      store.dispatch({
        type: 'set',
        payload: {
          interest_list: interest_array,
          interest_group: res.data,
        },
      });
    }
  };

  getPopup = async () => {
    const res = await apiClient.get('/web/popup');

    for (var i = 0; i < res.data.length; i++) {
      res.data[i].is_show = true;
    }

    store.dispatch({
      type: 'set',
      payload: {
        popup_list: res.data,
      },
    });
  };
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Analytics />
          <Switch>
            <Route path="/" name="home" render={(props) => <MainLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
